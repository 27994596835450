import { CardItemModel, CardsModel, TokenURIsModel } from "../models/card";
import { api } from "../utils/api";

const BATCH_SIZE = 50;

const useCardsService = () => {
  const fetchCardData = async (url: string): Promise<CardItemModel | null> => {
    try {
      const { data } = await api.get(url);
      return { id: data.tokenId, name: data.name, image: data.image };
    } catch (error) {
      console.error(`Error fetching data for card items from ${url}:`, error);
      return null;
    }
  };

  const getDataFromUrls = async (urls: string[]): Promise<CardItemModel[]> => {
    const results: CardItemModel[] = [];

    for (let i = 0; i < urls.length; i += BATCH_SIZE) {
      const batch = urls.slice(i, i + BATCH_SIZE);
      const batchResults = await Promise.all(batch.map(fetchCardData));
      results.push(...batchResults.filter((result): result is CardItemModel => result !== null));
    }

    return results;
  };

  const getAllCardsData = async (URIs: TokenURIsModel): Promise<CardsModel> => {
    const { normal, special, dinner } = URIs;

    const [normalData, specialData, dinnerData] = await Promise.all([
      getDataFromUrls(normal),
      getDataFromUrls(special),
      getDataFromUrls(dinner),
    ]);

    return { normal: normalData, special: specialData, dinner: dinnerData };
  };

  return { getAllCardsData };
};

export default useCardsService;
