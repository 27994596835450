import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import CardItemStyle from "./Style";
import CardModal from "../modal/Component";
import LazyImage from "../lazy/Component";

type Props = {
  img: string;
  name: string;
  idx: number;
};

const CardItem: FC<Props> = ({ img, name, idx }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <CardItemStyle item xxs={6} xs={4} md={3} lg={2} className="card-item">
        <Box className="card-box" onClick={() => setModalIsOpen(true)}>
          <LazyImage alt="card-image" src={img} idx={idx} />
          <Box className="card-description-box">
            <Typography variant="h4" className="card-description">
              {name}
            </Typography>
          </Box>
        </Box>
      </CardItemStyle>
      <CardModal img={img} isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
    </>
  );
};

export default CardItem;
