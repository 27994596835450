/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, FC, SetStateAction } from "react";
import { Box, Button, Typography } from "@mui/material";
import CardItemStyle from "./Style";
import { Close } from "@mui/icons-material";

type Props = {
  img: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const CardModal: FC<Props> = ({ img, isOpen = true, setIsOpen }) => {
  if (isOpen === false) return <></>;
  return (
    <CardItemStyle>
      <Box className="card-modal-backdrop-box" onClick={() => setIsOpen(false)} />
      <Box className="card-modal-image-box">
        <Box className="card-modal-close-box">
          <Button onClick={() => setIsOpen(false)} className="card-modal-button">
            <Typography variant="textSmallNormal"> Close </Typography>
            <Close />
          </Button>
        </Box>
        <img alt="card--modal-image" src={img} />
      </Box>
    </CardItemStyle>
  );
};

export default CardModal;
