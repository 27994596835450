import React, { FC, useEffect, useRef, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import phImage from "../../../assets/images/ph.jpg";

type ImgProps = {
  src: string;
  alt: string;
  idx: number;
};

const LazyImage: FC<ImgProps> = ({ src, alt, idx }) => {
  const [imageSrc, setImageSrc] = useState<string>(phImage);
  const [loading, setLoading] = useState<boolean>(true);

  const imageRef = useRef<HTMLImageElement>(null);

  const onLoad = () => {
    if (imageRef.current) {
      imageRef.current.classList.remove("has-error");
      imageRef.current.classList.add("loaded");
    }

    if (imageRef.current?.src === src) {
      setLoading(false);
    }
  };

  const onError = () => {
    if (imageRef.current) {
      imageRef.current.classList.add("has-error");
      imageRef.current.onerror = null;
    }

    console.log("ERROR");

    setTimeout(() => {
      if (imageRef.current) {
        imageRef.current.src = src;
        console.log("RETRY", src);
      }
    }, 3000);
  };

  useEffect(() => {
    let observer: IntersectionObserver | null = null;
    let didCancel = false;

    if (imageRef.current && imageSrc !== src) {
      setLoading(true);
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setTimeout(() => {
                  setImageSrc(src);
                }, (idx % 20) * 100); // Add 100 ms delay between image loads (batch20)
                observer?.unobserve(imageRef.current!);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: "70%",
          }
        );
        observer.observe(imageRef.current);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef.current!);
      }
    };
  }, [src, imageSrc, idx]);

  return (
    <>
      {loading && (
        <Skeleton
          variant="rectangular"
          sx={{ position: "absolute", height: "100%", width: "100%", bgcolor: "grey.900" }}
        />
      )}
      <img
        ref={imageRef}
        src={imageSrc}
        alt={alt}
        onLoad={onLoad}
        onError={onError}
        style={{ opacity: loading ? "0.001" : "1", transition: "opacity 0.3s ease-in-out" }}
      />
    </>
  );
};

export default LazyImage;
