/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Typography } from "@mui/material";
import { type FC, useEffect, useState } from "react";
import { useStoreContext } from "../../../hooks/Contexts";
import GetStartedStyle from "./Style";
import ButtonBlue from "../../form/button-blue/Component";
import { ContainerMaxWidth } from "../../../contexts/Store";
//import usePayStatus from "../../../services/PayStatus";
import TransactionLoader from "../../transaction-loader/Component";
import { componentTracking } from "../../../utils/utils";

const GetStarted: FC = (): JSX.Element => {
  const { setStore } = useStoreContext();
  //const { status } = usePayStatus();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    componentTracking("checkout", {
      checkout_view: "Get Started",
    });

    (async () => {
      localStorage.removeItem("signIn");
      setIsLoading(false);
      setStore((prevState) => ({
        ...prevState,
        flowControl: {
          ...prevState.flowControl,
          backStep: undefined,
        },
        containerMaxWidth: ContainerMaxWidth.DEFAULT,
      }));
    })();
  }, []);

  const handleContinue = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          ...prevState.flowControl.steps.signIn,
          title: "Sign In",
        },
      },
    }));
  };

  if (isLoading) {
    return <TransactionLoader type="Simple Loading" />;
  }

  return (
    <GetStartedStyle>
      <Typography variant="h1">We Make It Easy To View Your Trump Digital Trading Cards</Typography>
      <Grid container justifyContent={"center"}>
        <Grid item xxxs={12} xxs={9} className="py-3 either" textAlign={"center"}>
          <Typography variant="textRegularNormal">You can either...</Typography>
        </Grid>
        <Grid item xxxs={12} xxs={11} xs={9} className="py-3">
          <Grid container className="get-started-choices-container" spacing={1}>
            <Grid item xxxs={2} xxs={1}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0.5C8.34403 0.5 0.5 8.34403 0.5 18C0.5 27.656 8.34403 35.5 18 35.5C27.656 35.5 35.5 27.656 35.5 18C35.5 8.34403 27.656 0.5 18 0.5ZM18 2.02174C26.8336 2.02174 33.9783 9.16644 33.9783 18C33.9783 26.8336 26.8336 33.9783 18 33.9783C9.16644 33.9783 2.02174 26.8336 2.02174 18C2.02174 9.16644 9.16644 2.02174 18 2.02174ZM18 9.62746C17.5595 12.0942 15.7975 12.6243 13.3308 12.7126V14.1957H18V26.3696H19.498V9.62746H18Z"
                  fill="black"
                />
              </svg>
            </Grid>
            <Grid
              item
              xxxs={10}
              xxs={11}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="textRegularSemibold">
                Simply enter your email address on the next page.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xxxs={12} xxs={11} xs={9} className="py-3 payment-method-divider">
          <Grid container className="get-started-choices-container">
            <Grid item xxxs={5} textAlign="right">
              <svg
                width="101"
                height="2"
                viewBox="0 0 101 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="1" y1="1" x2="100" y2="1" stroke="#E6E6E6" strokeLinecap="round" />
              </svg>
            </Grid>
            <Grid item xxxs={2} textAlign="center">
              <Typography variant="textRegularNormal">or</Typography>
            </Grid>
            <Grid item xxxs={5}>
              <svg
                width="101"
                height="2"
                viewBox="0 0 101 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="1" y1="1" x2="100" y2="1" stroke="#E6E6E6" strokeLinecap="round" />
              </svg>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xxxs={12} xxs={11} xs={9} className="py-3">
          <Grid container className="get-started-choices-container">
            <Grid item xxxs={2} xxs={1}>
              <svg
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9999 0.400024C8.11641 0.400024 0.899902 7.61653 0.899902 16.5C0.899902 25.3835 8.11641 32.6 16.9999 32.6C25.8834 32.6 33.0999 25.3835 33.0999 16.5C33.0999 7.61653 25.8834 0.400024 16.9999 0.400024ZM16.9999 1.80002C25.1268 1.80002 31.6999 8.37315 31.6999 16.5C31.6999 24.6269 25.1268 31.2 16.9999 31.2C8.87303 31.2 2.2999 24.6269 2.2999 16.5C2.2999 8.37315 8.87303 1.80002 16.9999 1.80002ZM16.9999 8.80002C14.6666 8.80002 13.3072 10.07 12.6987 11.2869C12.0903 12.5039 12.0999 13.7 12.0999 13.7H13.4999C13.4999 13.7 13.5096 12.7962 13.9511 11.9131C14.3926 11.0301 15.1332 10.2 16.9999 10.2C16.9999 10.2 17.9038 10.2097 18.7868 10.6512C19.6699 11.0927 20.4999 11.8334 20.4999 13.7C20.4999 14.725 20.0976 15.4287 19.3993 16.1076C18.701 16.7866 17.6972 17.3832 16.6472 17.9957C15.5972 18.6082 14.501 19.2366 13.6243 20.0889C12.7476 20.9412 12.0999 22.075 12.0999 23.5V24.2H21.8999V22.8H13.6517C13.802 22.1536 14.0855 21.593 14.6005 21.0924C15.2988 20.4135 16.3026 19.8168 17.3526 19.2043C18.4026 18.5918 19.4988 17.9635 20.3755 17.1112C21.2522 16.2588 21.8999 15.125 21.8999 13.7C21.8999 11.3667 20.6299 10.0073 19.413 9.39885C18.196 8.79037 16.9999 8.80002 16.9999 8.80002Z"
                  fill="black"
                />
              </svg>
            </Grid>
            <Grid item xxxs={10} xxs={11}>
              <Typography
                variant="textRegularSemibold"
                sx={{ display: { xxxs: "none", sm: "block" } }}
              >
                Already have a way to store your Trump Trading Cards? Then connect to “External
                wallet” on the next page.
              </Typography>
              <Typography
                variant="textRegularSemibold"
                sx={{ display: { xxxs: "block", sm: "none" } }}
              >
                Already have a way to store your Trump Trading Cards? Open this page in your
                MetaMask browser.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xxxs={12} xs={9} className="py-3 mt-3">
          <Grid container>
            <Grid item xxxs={12}>
              <ButtonBlue
                props={{ variant: "contained", fullWidth: true }}
                onClick={handleContinue}
              >
                Continue
              </ButtonBlue>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GetStartedStyle>
  );
};

export default GetStarted;
