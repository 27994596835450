import { Box, styled } from "@mui/material";
import theme from "../../../utils/theme";

const CardsStyle = styled(Box)(() => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 1000000,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  ".card-modal-backdrop-box": {
    backgroundColor: "rgba(0,0,0,0.9)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  ".card-modal-image-box": {
    height: "80%",
    position: "relative",
    img: {
      height: "100%",
      width: "100%",
      objectFit: "contain",
      borderRadius: "10px",
      boxShadow:
        "rgba(255, 255, 255, 0.22) 0px 2px 4px 0px, rgba(255, 255, 255, 0.50) 0px 2px 16px 0px",
      [theme.breakpoints.down("xxs")]: {
        boxShadow: "unset",
      },
    },
  },
  ".card-modal-close-box": {
    color: theme.palette.color1.main,
    position: "absolute",
    right: "-100px",
    display: "flex",
    width: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.5),
    top: "-8%",
    [theme.breakpoints.down("sm")]: {
      left: "45%",
      top: "-5%",
    },
    ".card-modal-button": {
      color: theme.palette.color1.main,
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
}));

export default CardsStyle;
