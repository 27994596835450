export const collectTrumpCardsSite = process.env.REACT_APP_COLLECT_TRUMPCARDS_SITE;
// web3auth variables
export const web3authClientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;
export const chainId = process.env.REACT_APP_CHAIN_ID;
export const rpcTarget = process.env.REACT_APP_RPC_TARGET;
export const web3authNetwork: any = process.env.REACT_APP_WEB3_AUTH_NETWORK;

//contract addresses
export const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
export const specialTokenAddress = process.env.REACT_APP_SPECIAL_TOKEN_ADDRESS;
export const dinnerTokenAddress = process.env.REACT_APP_DINNER_TICKET_TOKEN_ADDRESS;
export const minterAddress = process.env.REACT_APP_MINTER_ADDRESS;
export const wethAddress = process.env.REACT_APP_WETH_ADDRESS;
export const zoomAddress = process.env.REACT_APP_ZOOM_ADDRESS;

//token URIs
export const mainCollectionTokenUri = process.env.REACT_APP_MAIN_TOKEN_URI;
export const specialCollectionTokenUri = process.env.REACT_APP_SPECIAL_TOKEN_URI;
export const dinnerCollectionTokenUri = process.env.REACT_APP_DINNER_TOKEN_URI;

//api
export const startKycEndpoint = process.env.REACT_APP_KYC_API;
export const verifyUserDataEndpoint = process.env.REACT_APP_VERIFY_USER_API;
export const paymentServer = process.env.REACT_APP_PAYMENT_SERVER;
export const counterEndpoint = process.env.REACT_APP_COUNTER_ENDPOINT;
export const payStatusEndpoint = process.env.REACT_APP_SOLDOUT_STATUS_ENDPOINT;
export const payConfigEndpoint = process.env.REACT_APP_PAYMENT_CONFIG_ENDPOINT;

//others
export const appDomain = process.env.REACT_APP_DOMAIN;
export const fiatPrice: number = Number(process.env.REACT_APP_FIAT_PRICE);
export const serviceFeePercentage: number = Number(process.env.REACT_APP_SERVICE_FEE_PERCENTAGE);
export const web3authLogoUrl = process.env.REACT_APP_WEB3AUTH_LOGO_URL;
export const shippingPrice = Number(process.env.REACT_APP_SHIPPING_PRICE);
export const maxMintableTokens = 250;
